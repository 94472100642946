import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../../../api/axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { UnSuccessfulRequest } from "../../../../../components/modals/confirmationModal";
import SelectAreas from "../../../../../components/select-search/AreasSelect";

export default function AdminCustomerInfo({ value, handleChange }) {
    const { t } = useTranslation();
    const order = useSelector((state) => state.order);
    const [foundCustomer, setFoundCustomer] = useState(undefined);
    const adminAddresses = useSelector(
        (state) => state.selectedData?.selectedSeller?.saved_address
    );
    const adminSelectedAddress = useSelector(
        (state) => state.order.seller_address
    );
    const selectedSeller = useSelector(
        (state) => state.selectedData?.selectedSeller
    );
    const workModel = useMemo(
        () =>
            adminAddresses?.find((a) => a.id === adminSelectedAddress)
                ?.new_work_model,
        []
    );
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [areas, setAreas] = useState([]);
    const [error, setError] = useState();
    const fields = [
        {
            label: t("phone"),
            name: "phone",
            required: true,
            innerProps: {
                onBlur: (e) => {
                    console.log(e.target.value);
                    getClients(e.target.value);
                },
            },
            extraRules: [
                {
                    // phone must be in e164 format
                    pattern: /^\+[1-9]\d{10,14}$/,
                    message: t("phoneErr"),
                },
            ],
        },
        {
            label: t("fullname"),
            name: "name",
            required: true,
        },
        {
            label: t("altPhone"),
            name: "phone_alt",
            extraRules: [
                {
                    pattern: /^\+[1-9]\d{10,14}$/,
                    message: t("phoneErr"),
                },
            ],
        },
        {
            label: t("area"),
            name: "area",
            required: true,
            type: "select",
            options: areas,
        },
        {
            label: t("address"),
            name: "address",
            required: true,
        },
    ];

    useEffect(() => {
        form.setFieldsValue(order.clientData);
        if (order.clientData?.phone) getClients(order.clientData.phone);
    }, []);
    useEffect(() => {
        getAreas();
    }, [workModel]);
    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };
    const getAreas = async () => {
        let result = await axiosInstance
            .get(
                `/FilterAreas/?page_size=10000&${
                    +workModel?.model_type === 1
                        ? "new_weight_work_model"
                        : "new_diemention_work_model"
                }=${workModel?.id}`
            )
            .catch((err) => console.log(err));

        if (result) {
            setAreas(result.data.data);
        }
    };

    async function getClients(phone) {
        const res = await axiosInstance.get("/FilterClients/", {
            params: {
                seller: selectedSeller.id ?? undefined,
                phone,
                only_area_id: true,
            },
        });

        if (res) {
            const resFoundCustomer =
                phone.length > 0 ? res.data.data?.[0] : undefined;
            const formattedFoundCustomer = resFoundCustomer
                ? {
                      id: resFoundCustomer.id,
                      phone: resFoundCustomer.phone,
                      name: resFoundCustomer.name,
                      phone_alt: resFoundCustomer.phone_alt,
                      area: resFoundCustomer.client_addresses[0]?.area,
                      address: resFoundCustomer.client_addresses[0]?.address,
                      address_id: resFoundCustomer.client_addresses[0]?.id,
                  }
                : undefined;
            setFoundCustomer(formattedFoundCustomer ?? undefined);
            if (formattedFoundCustomer)
                form.setFieldsValue(formattedFoundCustomer);
            else {
                form.resetFields();
                form.setFieldValue("phone", phone);
            }
        }
    }

    const create = async (values) => {
        let data = {
            seller: selectedSeller.id,
            ...values,
        };
        console.log(data);
        let result;
        if (foundCustomer) {
            const addressResult = await axiosInstance
                .put(`/ClientAddress/${foundCustomer.address_id}`, {
                    area: data.area,
                    address: data.address,
                })
                .catch((err) =>
                    setError(err.response.data?.non_field_errors?.[0])
                );
            result = await axiosInstance
                .put(`/SavedClient/${foundCustomer.id}`, data)
                .catch((err) =>
                    setError(err.response.data?.non_field_errors?.[0])
                );
        } else {
            result = await axiosInstance
                .post(`/SavedClient/`, data)
                .catch((err) =>
                    setError(err.response.data?.non_field_errors?.[0])
                );
        }

        if (result) {
            dispatch(
                addOrder({
                    ...order,
                    clientData: data,
                    client: foundCustomer ? foundCustomer.id : result.data.id,
                    client_address: result.data.client_addresses[0]?.id,
                    seller_address: adminSelectedAddress,
                })
            );
            handleChange(null, parseInt(value) + 1);
        }
    };
    return (
        <div>
            <Row>
                <Col sm={12} md={10} lg={7}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={create}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        {fields.map((field) => {
                            const {
                                type,
                                options,
                                required,
                                extraRules,
                                innerProps,
                                ...fieldData
                            } = field;
                            return (
                                <Form.Item
                                    key={field.name}
                                    rules={[
                                        { required },
                                        ...(extraRules ?? []),
                                    ]}
                                    {...fieldData}
                                >
                                    {field.name === "area" ? (
                                        <SelectAreas />
                                    ) : type === "select" ? (
                                        <Select {...innerProps}>
                                            {options.map((option) => (
                                                <Select.Option
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input type={type} {...innerProps} />
                                    )}
                                </Form.Item>
                            );
                        })}
                    </Form>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col className="text-end" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>
                    <input
                        onClick={() => form.submit()}
                        className="confirm"
                        value={t("proceed")}
                        type="submit"
                    />
                </Col>
            </Row>
            <UnSuccessfulRequest
                show={error}
                hide={() => setError(undefined)}
                title={t("createOrderError")}
                body={error}
            />
        </div>
    );
}
